<template>
  <div class="info-box">
    <div class="icon">
      <i class="fas fa-info" />
    </div>
    <div class="body-content w-100">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
}
</script>

<style lang="scss">
.info-box {
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ecf0f3;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  column-gap: 10px;

  .icon {
    i {
      font-size: 20px;
      line-height: 20px;
      color: #ffa800;
      margin-top: 3px;
    }
  }

  .body-content {
    font-size: 13px;
    color: #6c7293;

    ul {
      list-style: none;
    }
  }
}
</style>
